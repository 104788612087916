<template>
  <div>
    <!-- Error404 v-if="error404occured" / -->

    <!-- Hero -->
    <div class="bg-img-hero" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/ben-career-hero.svg`)})`}">
      <div class="container space-2 space-top-3">
        <div class="row align-items-lg-center">
          <div class="col-lg-6 mb-3 mb-lg-0">
            <div class="mb-4">
              <h1>{{ CouponInfo.heroTitle }}</h1>
              <p class="lead">{{ CouponInfo.heroDescription }}</p>
            </div>

            <div class="w-sm-65">
              <div class="input-group">
                <input
                  ref="heroClone"
                  readonly
                  :value="CouponInfo.couponCode"
                  class="form-control bg-white"
                  @focus="$event.target.select()"
                >
                <div class="input-group-append">
                  <button class="btn btn-block btn-primary" @click="heroCopy">Copy Code</button>
                </div>
              </div>
            </div>

            <p class="small mt-3 text-danger"><i class="far fa-clock mr-1" /> Limited-time offer! Expire on <span class="font-weight-bold">{{ CouponInfo.expiryDate }}</span>.</p>
          </div>

          <div class="col-lg-6">
            <div class="w-sm-80 w-lg-100 mx-auto">
              <img class="img-fluid" :src="require(`@/assets/svg/illustrations/${CouponInfo.heroImage}.svg`)">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Details -->
    <div class="container space-2">
      <div class="position-relative w-lg-85 mx-lg-auto mb-4">
        <div class="card shadow">
          <div class="card-body p-6">
            <div class="text-center mb-5">
              <h2 class="mb-4">{{ CouponInfo.detailTitle }}</h2>
              <div class="w-md-85 mx-auto" v-html="CouponInfo.detailDescription" />
            </div>
            <div class="row">
              <div v-for="(item, index) in CouponInfo.benefits" :key="index" class="col-12 col-md-6 mb-3 mx-auto">
                <div class="border-dashed rounded p-3 h-100">
                  <div class="media align-items-center">
                    <figure class="w-100 max-w-6rem mr-4">
                      <img class="img-fluid" :src="require(`@/assets/svg/icons/${item.icon}.svg`)">
                    </figure>
                    <div class="media-body">
                      <span v-html="item.detail" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="termsCollapse" class="collapse">
              <hr>
              <div>
                <h6>Terms & Conditions</h6>
                <ul class="mb-0 font-size-1">
                  <li v-for="(item, index) in CouponInfo.terms" :key="index">{{ item.detail }}</li>
                </ul>
              </div>
            </div>
            <a class="link link-collapse small font-size-1 font-weight-bold pt-3 text-primary" data-toggle="collapse" href="#termsCollapse" role="button" aria-expanded="false" aria-controls="termsCollapse">
              <span class="link-collapse-default">Terms & Conditions</span>
              <span class="link-collapse-active">Read Less</span>
              <span class="link-icon ml-1">+</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- How to Redeem? -->
    <div class="container space-2">
      <div class="text-center">
        <h2 class="mb-6">How to Redeem?</h2>
      </div>
      <div class="row">
        <div v-for="(item, index) in Steps" :key="index" class="col-md-3 mb-4 mb-md-0">
          <div class="text-center">
            <figure class="max-w-10rem mx-auto mb-3">
              <img class="img-fluid" :src="require(`@/assets/svg/icons/${item.icon}`)">
            </figure>
            <h3>{{ item.title }}</h3>
            <p v-html="item.description" />
          </div>
        </div>
      </div>
      <div class="text-center mt-6">
        <p class="font-size-1">Looking for more instructions on redeeming the offers?</p>
        <a class="btn btn-primary" href="https://blog.openapihub.com/en-us/how-to-apply-a-coupon-code-to-your-selected-api-portal-plan/" target="_blank">See Step-by-Step Guide</a>
      </div>
    </div>

    <!-- CTA -->
    <div class="bg-light">
      <div class="container space-2">
        <div class="row align-items-lg-center">
          <div class="col-lg-6 mb-3 mb-lg-0">
            <div class="w-60 w-lg-50 mx-auto">
              <img class="img-fluid" src="@/assets/svg/illustrations/giftbox.svg">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-4">
              <h3>{{ $t(CouponInfo.ctaTitle) }}</h3>
              <p>{{ $t(CouponInfo.ctaDescription) }}</p>
            </div>
            <div class="w-sm-60">
              <div class="input-group">
                <input
                  ref="ctaClone"
                  readonly
                  :value="CouponInfo.couponCode"
                  class="form-control bg-white"
                  @focus="$event.target.select()"
                >
                <div class="input-group-append">
                  <button class="btn btn-block btn-primary" @click="ctaCopy">Copy Code</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- More FabriXAPI features -->
    <OahOverview class="space-top-2" />

  </div>
</template>

<script>
import 'bootstrap'
import couponList from './couponList.json'
import OahOverview from '@/components/oah-overview'

export default {
  name: 'CouponsDetails',
  components: {
    OahOverview
  },
  data() {
    return {
      // text: 'CouponInfo.expiryDate',
      coupon_id: '',
      CouponInfo: {},
      couponList: couponList,
      error404occured: false,
      Steps: [
        {
          title: '1. Login to Provider Admin Portal',
          description: '<a href="https://provider-portal.fabrixapi.com/" target="_blank">Log in</a> and select an API Portal to start with',
          icon: 'icon-48.svg'
        },
        {
          title: '2. Go to Plans & Pricing',
          description: 'Navigate to the page and view all available plans',
          icon: 'icon-31.svg'
        },
        {
          title: '3. Select a plan',
          description: 'Click the "Upgrade Now" button to upgrade to your desired plan',
          icon: 'icon-40.svg'
        },
        {
          title: '4. Enter Coupon Code',
          description: 'Enter code on Checkout Page. And everything is all set!',
          icon: 'icon-39.svg'
        }
      ]
    }
  },
  watch: {
    $route(to, from) {
      this.coupon_id = this.$route.params && this.$route.params.id
      this.error404occured = false
      this.getCouponInfo()
    }
  },
  created() {
    this.coupon_id = this.$route.params && this.$route.params.id
    this.getCouponInfo()
  },
  mounted: {

  },
  methods: {
    getCouponInfo() {
      const id = this.coupon_id
      const filteredCoupon = couponList.Items.filter(function(item) {
        return item.id === id
      })
      if (filteredCoupon.length > 0) {
        this.CouponInfo = filteredCoupon[0]
      } else {
        this.error404occured = true
      }
    },
    heroCopy() {
      this.$refs.heroClone.focus()
      document.execCommand('copy')
    },
    ctaCopy() {
      this.$refs.ctaClone.focus()
      document.execCommand('copy')
    }
  },
  metaInfo() {
    return {
      title: 'FabriXAPI',
      titleTemplate: `Promo codes and coupons | %s`,
      link: [
        { rel: 'canonical', href: `https://www.fabrixapi.com/coupons/${this.CouponInfo.id}` }
      ],
      meta: [
        { name: 'description', content: `Find the latest promo codes to register your FabriXAPI Provider Plan at even lower prices. Grab the discount now!` },
        { property: 'og:site_name', content: 'FabriXAPI' },
        { property: 'og:title', content: `Promo codes and coupons | FabriXAPI` },
        { property: 'og:description', content: `Find the latest promo codes to register your FabriXAPI Provider Plan at even lower prices. Grab the discount now!` },
        { property: 'og:image', content: 'https://www.fabrixapi.com/assets/img/thumbnails/fabrixapi-thumbnail.jpg' },
        { property: 'og:url', content: `https://www.fabrixapi.com/coupons/${this.CouponInfo.id}` }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
